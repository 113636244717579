import axios from '@/request/http'
import base from '@/request/base'

/**
 * 存储用户链接来源
 * @param {object} params
 */
export const saveUserSource = (params) => {
  return axios.get(`https://acadmin.yinolink.com/cloud/user/user_source`, { params })
}

/** 网站埋点 */
export const handleEventTracking = (data) => {
  return axios.post(`https://acadmin.yinolink.com/cloud/user/set_clickhouse_log`, data)
}
